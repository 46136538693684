import { FC, memo } from 'react'
import RegDialog from '../../RegDialog/desktop'
import { EQueueElements } from '../../RegDialog/types'
import { IParentFormDialogSuccessProps } from '../types'
import { socialText } from '../constants'

const ParentFormDialogSuccess: FC<IParentFormDialogSuccessProps> = (props) => {
  const { open, onClose, isInstitution } = props

  return (
    <RegDialog
      title={socialText.title}
      description={isInstitution ? socialText.description2 : socialText.description}
      open={open}
      onClose={onClose}
      params={{ templateId: 26 }}
      queue={
        isInstitution
          ? [EQueueElements.tg, EQueueElements.vk]
          : [EQueueElements.wa, EQueueElements.tg]
      }
    />
  )
}

export default memo(ParentFormDialogSuccess)
