import React, { FC, memo, useMemo } from 'react'
import styled from 'styled-components'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { ErrorMessage, Field, Formik } from 'formik'
import { Form } from '@ucheba/ui/components/Form/desktop'
import { useSelector } from 'react-redux'
import { TextField } from '@ucheba/ui/components/TextField/desktop'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { ErrorText } from '@ucheba/ui/components/ErrorText/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { Radio } from '@ucheba/ui/components/Radio/desktop'
import { useRouter } from 'next/router'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { authPhoneSelectors } from '@ucheba/store/auth/phone'
import { Select } from '@ucheba/ui/components/Select/desktop'
import content from '../../../../AppliactionForm/data.json'
import { IEventBrandingUchebaRegistration } from '../types'
import { useEventBrandingUchebaReg } from '../bll'
import PhoneConfirmDialog from '../../../../RequestForm/desktop/PhoneConfirmDialog'

const Block = styled.div`
  border-radius: 8px;
  box-shadow:
    0 25px 30px 0 rgba(3, 15, 35, 0.1),
    0 0 20px 0 #030f230c;
`

const EventBrandingUchebaRegisration: FC<IEventBrandingUchebaRegistration> = (props) => {
  const { onClickRegistration, institution, onSubmitEventReg } = props

  const apiErrors = useSelector(authPhoneSelectors.error)

  const { query } = useRouter()

  const {
    initialValues,
    onSubmit,
    onPhoneConfirmSubmit,
    phone,
    needConfirmPhone,
    isLoading,
    onPhoneConfirmClose,
  } = useEventBrandingUchebaReg(onClickRegistration)

  const formTitle = useMemo(() => {
    return institution
      ? 'Зарегистрируйтесь на мероприятие'
      : 'Зарегистрируйтесь<br/> на мероприятие и <span style="color: #ff6a00">получите гид абитуриента в подарок</span>'
  }, [institution])

  const formSubtitle = useMemo(() => {
    return institution
      ? 'Мы обязательно напомним вам о мероприятии и пришлем ссылку для подключения'
      : 'А еще мы напомним о мероприятии и пришлем ссылку для подключения'
  }, [institution])

  return (
    <Block>
      <Box spacing='xlarge'>
        <Text textAlign='center' fontSize='h3' lineHeight='h3' fontWeight='extrabold'>
          {formTitle}
        </Text>

        <Spacing spacing='xxsmall' />

        <Text textAlign='center'>{formSubtitle}</Text>

        <Spacing spacing='large' />

        <Formik initialValues={initialValues} onSubmit={onSubmitEventReg || onSubmit}>
          {(formikProps): JSX.Element => (
            <Form apiErrors={apiErrors} fillByProfile={!(query.name && query.phone)}>
              <Field as={TextField} name={EDataKeys.name} label='Имя' required />

              <ErrorMessage component={ErrorText} name={EDataKeys.name} />

              <Spacing spacing='large' />

              <Field
                as={TextField}
                name={EDataKeys.phone}
                label='Телефон'
                required
                type='tel'
              />

              <ErrorMessage component={ErrorText} name={EDataKeys.phone} />

              <Spacing spacing='large' />

              <Field
                values={initialValues.classForm}
                items={initialValues.classForm}
                name={EDataKeys.classForm}
                keyValue='name'
                label='Класс'
                as={Select}
                required
                onChange={(_, classForm): void =>
                  formikProps.setFieldValue('classForm', classForm)
                }
              />

              <Spacing spacing='large' />

              <Grid spacing='small'>
                <GridCol width='auto'>
                  <Field
                    as={Radio}
                    type='radio'
                    name={EDataKeys.isParent}
                    value='false'
                    label={content.application.fields.enrollee}
                  />
                </GridCol>

                <GridCol width='auto'>
                  <Field
                    as={Radio}
                    type='radio'
                    name={EDataKeys.isParent}
                    value='true'
                    label={content.application.fields.parent}
                  />
                </GridCol>
              </Grid>

              <Spacing spacing='large' />

              <Button color='orange' block type='submit'>
                Отправить
              </Button>
            </Form>
          )}
        </Formik>
      </Box>

      {phone && needConfirmPhone && !onSubmitEventReg ? (
        <PhoneConfirmDialog
          open={needConfirmPhone}
          phone={phone}
          onSubmit={onPhoneConfirmSubmit}
          onClose={onPhoneConfirmClose}
          isLoading={isLoading}
        />
      ) : null}
    </Block>
  )
}

export default memo(EventBrandingUchebaRegisration)
