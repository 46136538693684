import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import Link from 'next/link'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { ExternalLogo } from '@ucheba/ui/components/ExternalLogo/desktop'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Logo } from '@ucheba/ui/components/Logo/desktop'
import { mathVars } from '@ucheba/utils/helpers/styles/mixins'
import { Button } from '@ucheba/ui/components/Button/desktop'
import PlayIcon from '@ucheba/ui/icons/icon__circled-play.svg'
import { Dialog } from '@ucheba/ui/components/Dialog/desktop'
import { EEventFormat, IEventsListItemProps } from '../types'
import SC from '../base/EventsListItem.styles'
import { useEventsListItemCore, useEventsListItemDate } from '../bll'
import { EventsListItemButton } from './EventsListItemButton'
import EventsPageRegisterNotStartedEventNotice from '../../../pages/for-abiturients/events/index/desktop/EventsPageRegisterNotStartedEventNotice'
import EventsPageRegisterStartedEventNotice from '../../../pages/for-abiturients/events/index/desktop/EventsPageRegisterStartedEventNotice'
import EventsPageRegisterOfflineEventNotice from '../../../pages/for-abiturients/events/index/desktop/EventsPageRegisterOfflineEventNotice'
import ProfMarathonRegisterNotStartedEventNotice from '../../../pages/events/prof-marathon/calendar/index/desktop/ProfMarathonEventsPageRegisterNotStartedEventNotice'
import { nameUcheba } from '../contants'
import { forAbiturients } from '../../../routs'
import EventNoticeNotAllowedMessage from '../../EventPages/components/EventNotices/EventNoticeNotAllowedMessage/desktop'
import ParentFormDialog from '../../ParentFormDialog/desktop'
import EventBrandingUchebaRegisration from '../../EventPages/components/EventBrandingUchebaRegisration/desktop'
import ParentFormDialogSuccess from '../../ParentFormDialog/desktop/ParentFormDialogSuccess'
import PhoneConfirmDialog from '../../RequestForm/desktop/PhoneConfirmDialog'
import { useEventBrandingUchebaReg } from '../../EventPages/components/EventBrandingUchebaRegisration/bll'

const { Block: BlockSC } = SC

const Block = styled(BlockSC)`
  padding: ${({ isFinished }) =>
      isFinished ? mathVars(sv.spacing.xlarge, '+', 8) : sv.spacing.xlarge}
    0;

  border-top: none;
  border-top: 1px solid ${({ isDivider }) => (isDivider ? sv.color.gray9 : 'none')};
`

const GridColSC = styled(GridCol)`
  flex-grow: 1;
`

/** Событие */

const EventsListItem: FC<IEventsListItemProps> = (props) => {
  const {
    isDivider = true,
    name,
    id,
    streamUrl,
    pathname,
    formats,
    needVk,
    isFinished,
    isFinishedLine,
    linkReg,
    onClickAction,
    exam,
    institution: institutionProps,
    hasBranding,
    rubric,
  } = props

  const onClick = (): void => {
    if (onClickAction) {
      onClickAction(id)
    }
  }

  const {
    institution,
    description,
    buttonData,
    onClickRegistration,
    registersPending,
    notices,
    isEventStartedOrHour,
    messengersDialog,
    href,
  } = useEventsListItemCore({ ...props, pathname })

  const { format, date } = useEventsListItemDate(props)

  const isHybridEvent = useMemo(() => {
    return (
      formats.some((val) => val.value === EEventFormat.online) &&
      formats.some((val) => val.value === EEventFormat.offline)
    )
  }, [formats])

  const [open, setOpen] = useState(false)

  const onCloseRegisterForm = useCallback(() => {
    setOpen(false)
  }, [])

  const onClickBrandRegistration = useCallback(() => {
    if (hasBranding) {
      setOpen(true)
      return
    }
    onClickRegistration()
  }, [hasBranding, onClickRegistration])

  const {
    onSubmit,
    phone,
    needConfirmPhone,
    onPhoneConfirmClose,
    onPhoneConfirmSubmit,
    isLoading,
  } = useEventBrandingUchebaReg(onClickRegistration)

  const onSubmitEventReg = useCallback(
    (values: any) => {
      onSubmit(values)
      setOpen(false)
    },
    [onSubmit]
  )

  return (
    <Block isDivider={isDivider} isFinished={isFinishedLine} data-test-id='event'>
      {needVk ? (
        <ProfMarathonRegisterNotStartedEventNotice
          id={id}
          open={!!notices.onlineEventNotStartedNotice.open}
          isHybrid={isHybridEvent}
          needVk={needVk}
          linkReg={linkReg}
        />
      ) : (
        <EventsPageRegisterNotStartedEventNotice
          id={id}
          open={!!notices.onlineEventNotStartedNotice.open}
          isHybrid={isHybridEvent}
        />
      )}

      <EventsPageRegisterStartedEventNotice
        id={id}
        href={streamUrl}
        open={!!notices.onlineEventStartedNotice.open}
        needVk={needVk}
      />

      <EventsPageRegisterOfflineEventNotice
        id={id}
        open={notices.offlineEventNotice.open}
        isEventStarted={isEventStartedOrHour}
        needVk={needVk}
      />

      <EventNoticeNotAllowedMessage
        id={id}
        open={!!notices.notAllowedMessegerNotice.open}
        isHybrid={isHybridEvent}
        isOnline={formats.some((val) => val.value === EEventFormat.online)}
        onClose={notices.notAllowedMessegerNotice.removeNotice}
      />

      <Grid nowrap>
        <GridColSC width='auto' shrink='initial'>
          {isFinished && (
            <>
              <Text fontWeight='semibold' color='gray40'>
                Вебинар закончился
              </Text>

              <Spacing spacing='usmall' />
            </>
          )}

          {(date || format) && (
            <>
              <Text fontWeight='semibold' html={false}>
                {date}

                {date && format && (
                  <Text color='gray25' as='span'>
                    {' • '}
                  </Text>
                )}

                {format}
              </Text>

              <Spacing spacing='usmall' />
            </>
          )}

          {institution ? (
            <>
              <Text fontWeight='semibold' html={false}>
                <Link href={institution.href} passHref>
                  {institution.name}
                </Link>

                {institution?.location?.name && (
                  <Text color='gray25' as='span'>
                    {' • '}
                  </Text>
                )}

                {institution?.location?.name && (
                  <Text color='gray40' as='span'>
                    {institution.location.name}
                  </Text>
                )}
              </Text>

              <Spacing spacing='usmall' />
            </>
          ) : (
            <>
              <Text fontWeight='semibold' html={false}>
                <Link href={forAbiturients.ege.path} passHref>
                  {nameUcheba}
                </Link>
              </Text>

              <Spacing spacing='usmall' />
            </>
          )}

          <Link href={href}>
            <Text fontWeight='bold' color='blue' fontSize='h3' lineHeight='h3'>
              {name}
            </Text>
          </Link>

          <Spacing spacing='usmall' />

          {description && <Text>{description}</Text>}

          <Spacing spacing='small' />

          {isFinished ? (
            <Button
              href={href}
              StartIcon={<PlayIcon width='20px' height='20px' />}
              color='blue'
            >
              Смотреть трансляцию
            </Button>
          ) : (
            <EventsListItemButton
              onClick={onClickAction ? onClick : onClickBrandRegistration}
              progress={registersPending}
              href={streamUrl}
              registeredStatus={buttonData.eventRegisteredStatus}
            >
              {buttonData.buttonText}
            </EventsListItemButton>
            // <EventsListItemButton
            //   onClick={
            //     onClickAction
            //       ? onClick
            //       : !institutionProps && rubric?.id === 4
            //         ? () => setOpen(true)
            //         : onClickRegistration
            //   }
            //   progress={registersPending}
            //   href={streamUrl}
            //   registeredStatus={buttonData.eventRegisteredStatus}
            // >
            //   {buttonData.buttonText}
            // </EventsListItemButton>
          )}
        </GridColSC>

        <GridCol width='96px'>
          {institution?.logo ? (
            institution?.logo?.fallbackUrl && (
              <ExternalLogo
                logo={institution.logo}
                href={institution.href}
                maxWidth='64px'
                maxHeight='80px'
                alt={institution.name}
              />
            )
          ) : (
            <Link href={forAbiturients.ege.path} passHref>
              <Logo color='blue' width='64px' isSquare />
            </Link>
          )}
        </GridCol>
      </Grid>

      <ParentFormDialog id={id} exam={exam} />

      <ParentFormDialogSuccess
        open={messengersDialog.isActive}
        onClose={messengersDialog.closeDialog}
        isInstitution={!!institution}
      />

      <Dialog size='small' open={open} onClose={onCloseRegisterForm} spacing='null'>
        <EventBrandingUchebaRegisration
          {...props}
          onClickRegistration={onClickRegistration}
          onSubmitEventReg={onSubmitEventReg}
        />
      </Dialog>

      {phone && needConfirmPhone ? (
        <PhoneConfirmDialog
          open={needConfirmPhone}
          phone={phone}
          onSubmit={onPhoneConfirmSubmit}
          onClose={onPhoneConfirmClose}
          isLoading={isLoading}
        />
      ) : null}
    </Block>
  )
}

export { EventsListItem }
export default memo(EventsListItem)
